<template>
  <v-timeline-item v-if="item" :color="sColor" fill-dot left small>
    <template #opposite>
      <div class="text-body-1" v-text="sDate" />
    </template>

    <v-card :elevation="0" color="blue-grey lighten-5" rounded>
      <v-card-text class="text-right pa-2">
        <div v-if="item.tags.length" class="mb-2">
          <v-chip
            v-for="tag in item.tags"
            :key="`item.${tag}`"
            :color="getTagColor(tag)"
            class="mr-2 white--text"
            pill
            small
            label
            v-text="$t(`release.tag.${tag}`)"
          />
        </div>
        <div v-html="item.description" />
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ReleaseNoteData, ReleaseTag } from "@planetadeleste/vue-mc-gw";
import dayjs from "dayjs";

@Component
export default class ReleaseNoteItem extends Vue {
  @Prop(Object) readonly item!: ReleaseNoteData;

  get sDate() {
    return this.item ? dayjs(this.item.created_at).format("L") : null;
  }

  get sTag(): ReleaseTag | null {
    return this.item && this.item.tags && this.item.tags.length
      ? this.item.tags[0]
      : null;
  }

  get sColor(): string {
    if (!this.sTag) {
      return "indigo darken-4";
    }

    return this.getTagColor(this.sTag);
  }

  getTagColor(sTag: ReleaseTag): string {
    switch (sTag) {
      case "new":
        return "blue darken-2";

      case "fix":
        return "orange darken-3";

      case "update":
        return "purple darken-1";
    }

    return "indigo darken-4";
  }
}
</script>
